import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { getComponentByAppId } from './find-component';
import { ResponsiveLayout } from '@wix/editor-platform-sdk-types';
import { WIX_FORUM as forumAppDefId } from '@wix/app-definition-ids';

const setResponsiveness = async (
  appToken: string,
  sdk: FlowEditorSDK,
  compRef: ComponentRef,
  maxWidth: number,
) => {
  try {
    const oldResponsiveLayout = await sdk.document.responsiveLayout.get(appToken, {
      componentRef: compRef,
    });

    const newComponentLayouts = oldResponsiveLayout.componentLayouts.map((componentLayout) => {
      return {
        ...componentLayout,
        maxWidth: { type: 'px', value: maxWidth },
        width: { type: 'percentage', value: 100 },
        height: { type: 'auto' },
      };
    });

    const newItemLayouts = oldResponsiveLayout.itemLayouts.map((itemLayout) => {
      if (itemLayout.type === 'GridItemLayout') {
        return {
          ...itemLayout,
          justifySelf: 'center',
          alignSelf: 'center',
          margins: {
            top: { type: 'percentage', value: 0 },
            left: { type: 'percentage', value: 0 },
            right: { type: 'percentage', value: 0 },
            bottom: { type: 'percentage', value: 0 },
          },
        };
      }
      return itemLayout;
    });

    const newResponsiveLayout = {
      ...oldResponsiveLayout,
      componentLayouts: newComponentLayouts,
      itemLayouts: newItemLayouts,
    } as ResponsiveLayout;

    await sdk.document.responsiveLayout.update(appToken, {
      componentRef: compRef,
      responsiveLayout: newResponsiveLayout,
    });
  } catch (e) {
    return Promise.resolve();
  }
};

export const setForumResponsiveness = async (appToken: string, sdk: FlowEditorSDK) => {
  const appData = await sdk.tpa.app.getDataByAppDefId(appToken, forumAppDefId);
  const component = await getComponentByAppId(appToken, sdk, appData.applicationId);
  const compRef = await sdk.components.getById(appToken, { id: component.id });

  if (!compRef) {
    return Promise.resolve();
  }

  await setResponsiveness(appToken, sdk, compRef, 980);
};

export const setRpwResponsiveness = (appToken: string, sdk: FlowEditorSDK, compRef: ComponentRef) =>
  setResponsiveness(appToken, sdk, compRef, 334);
