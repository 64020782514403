import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WIX_FORUM as forumAppDefId } from '@wix/app-definition-ids';
import { getComponentByAppId } from './find-component';
import {
  LAYOUT_TYPE_MAIN_PAGE,
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE,
  FORUM_STRUCTURE,
  IS_VIEWS_COUNT_ENABLED,
  IS_LIST_HASHTAGS_ENABLED,
  IS_PINTEREST_PIN_ENABLED,
  IS_SOCIAL_SHARING_LINKS_ENABLED,
  IS_BADGES_ENABLED,
  LAYOUT_TYPE_COMPACT,
  LAYOUT_TYPE_FEED,
  LAYOUT_TYPE_PINBOARD,
} from '@wix/communities-forum-client-commons';
import { ForumLayoutType } from '../../components/Forum/panels/components/layout-selector/forum-layout-type';
import { StyleParam } from '@wix/editor-platform-sdk-types';

const presets: Record<string, StyleParam[]> = {
  [ForumLayoutType.List]: [
    {
      type: 'number',
      key: LAYOUT_TYPE_MAIN_PAGE,
      param: {
        value: LAYOUT_TYPE_CLASSIC,
      },
    },
    {
      type: 'number',
      key: LAYOUT_TYPE,
      param: {
        value: LAYOUT_TYPE_CLASSIC,
      },
    },
    {
      type: 'number',
      key: FORUM_STRUCTURE,
      param: {
        value: 0,
      },
    },
    {
      type: 'boolean',
      key: IS_VIEWS_COUNT_ENABLED,
      param: {
        value: true,
      },
    },
    {
      type: 'boolean',
      key: IS_LIST_HASHTAGS_ENABLED,
      param: {
        value: true,
      },
    },
    {
      type: 'boolean',
      key: IS_SOCIAL_SHARING_LINKS_ENABLED,
      param: {
        value: true,
      },
    },
    {
      type: 'boolean',
      key: IS_PINTEREST_PIN_ENABLED,
      param: {
        value: false,
      },
    },
    {
      type: 'boolean',
      key: IS_BADGES_ENABLED,
      param: {
        value: false,
      },
    },
  ],
  [ForumLayoutType.Cards]: [
    {
      type: 'number',
      key: LAYOUT_TYPE_MAIN_PAGE,
      param: {
        value: LAYOUT_TYPE_COMPACT,
      },
    },
    {
      type: 'number',
      key: LAYOUT_TYPE,
      param: {
        value: LAYOUT_TYPE_PINBOARD,
      },
    },
    {
      type: 'number',
      key: FORUM_STRUCTURE,
      param: {
        value: 1,
      },
    },
    {
      type: 'boolean',
      key: IS_VIEWS_COUNT_ENABLED,
      param: {
        value: true,
      },
    },
    {
      type: 'boolean',
      key: IS_LIST_HASHTAGS_ENABLED,
      param: {
        value: true,
      },
    },
    {
      type: 'boolean',
      key: IS_SOCIAL_SHARING_LINKS_ENABLED,
      param: {
        value: true,
      },
    },
    {
      type: 'boolean',
      key: IS_PINTEREST_PIN_ENABLED,
      param: {
        value: false,
      },
    },
    {
      type: 'boolean',
      key: IS_BADGES_ENABLED,
      param: {
        value: false,
      },
    },
  ],
  [ForumLayoutType.Feed]: [
    {
      type: 'number',
      key: LAYOUT_TYPE_MAIN_PAGE,
      param: {
        value: LAYOUT_TYPE_COMPACT,
      },
    },
    {
      type: 'number',
      key: LAYOUT_TYPE,
      param: {
        value: LAYOUT_TYPE_FEED,
      },
    },
    {
      type: 'number',
      key: FORUM_STRUCTURE,
      param: {
        value: 1,
      },
    },
    {
      type: 'boolean',
      key: IS_VIEWS_COUNT_ENABLED,
      param: {
        value: false,
      },
    },
    {
      type: 'boolean',
      key: IS_LIST_HASHTAGS_ENABLED,
      param: {
        value: true,
      },
    },
    {
      type: 'boolean',
      key: IS_SOCIAL_SHARING_LINKS_ENABLED,
      param: {
        value: true,
      },
    },
    {
      type: 'boolean',
      key: IS_PINTEREST_PIN_ENABLED,
      param: {
        value: false,
      },
    },
    {
      type: 'boolean',
      key: IS_BADGES_ENABLED,
      param: {
        value: false,
      },
    },
  ],
};

export const setForumLayoutPresetSettings = async (
  appToken: string,
  sdk: FlowEditorSDK,
  layoutType: ForumLayoutType | null,
) => {
  const appData = await sdk.tpa.app.getDataByAppDefId(appToken, forumAppDefId);
  const component = await getComponentByAppId(appToken, sdk, appData.applicationId);
  const compRef = await sdk.components.getById(appToken, { id: component.id });

  if (!compRef || !layoutType) {
    return Promise.resolve();
  }

  return sdk.document.tpa.setStyleParams(appToken, {
    compRef,
    styleParams: presets[layoutType],
  });
};
