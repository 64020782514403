import { SETTINGS_TRANSLATION_NAME } from '../../components/Forum/Settings/constants/translation';
import { FlowEditorSDK, initI18n, EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

export const initializeI18N = async (
  editorSDK: FlowEditorSDK,
  appToken: string,
  httpClient: EditorScriptFlowAPI['httpClient'],
) => {
  const translationsUrl = await getUrl(editorSDK, appToken);
  let messages: { [key: string]: string } = {
    App_Manager_Wix_Forum_Main_CTA: 'Create a Post',
    App_Manager_Wix_Forum_Main_CTA_2: 'Manage Posts',
    App_Manager_Wix_Forum_Quick_Action_1: 'Edit Categories',
    App_Manager_Wix_Forum_Quick_Action_2: 'Customize Layout',
    App_Manager_Wix_Forum_Quick_Action_3: 'Customize Design',
  };

  try {
    const translationsResponse = await httpClient.get<{
      [key: string]: string;
    }>(translationsUrl);
    messages = translationsResponse.data;
  } catch {}

  const i18nInstance = initI18n({
    disableAutoInit: true,
    locale: 'en',
    messages,
  });

  i18nInstance.init();

  return i18nInstance;
};

const getUrl = async (editorSDK: FlowEditorSDK, appToken: string): Promise<string> => {
  const baseUrl = (await editorSDK.document.tpa.app.getDataByAppDefId('token', appToken)).appFields
    .platform?.baseUrls?.translationsBaseUrl;
  const language = await editorSDK.editor.environment.getLocale();

  return `${baseUrl}${SETTINGS_TRANSLATION_NAME}_${language}.json`;
};
