import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const getComponentByAppId = async (
  appToken: string,
  sdk: FlowEditorSDK,
  applicationId: number,
) => {
  const comps = (await sdk.tpa.app.getAllCompsByApplicationId(appToken, applicationId)) || [];
  return comps.find((c) => c.type === 'TPA') || { id: '', pageId: '' };
};
